













































































































































































































































.navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
}

.mainCustomerSelect {
    border: none;
    width: 100%;

    input {
        border: 1px solid #e3e3e3 !important;
        border-radius: 4px !important;
    }
}
